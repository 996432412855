@import '../../node_modules/normalize.css/normalize.css';

:root {
  --color: #0d0d0d;
  --bg-color: #fcfcfc;
  --font-size: 0.75rem;

  @media (prefers-color-scheme: dark) {
    --color: #fcfcfc;
    --bg-color: #0d0d0d;
  }

  @media (min-width: 375px) {
    --font-size: 1rem;
  }

  @media (min-width: 600px) and (min-height: 600px) {
    --font-size: 1.25rem;
  }

  @media (min-width: 800px) and (min-height: 800px) {
    --font-size: 1.5rem;
  }

  @media (min-width: 1000px) and (min-height: 1000px) {
    --font-size: 1.75rem;
  }

  @media (min-width: 1200px) and (min-height: 1200px) {
    --font-size: 2rem;
  }
}

.color-scheme-light {
  --color: #0d0d0d;
  --bg-color: #fcfcfc;
}

.color-scheme-dark {
  --color: #fcfcfc;
  --bg-color: #0d0d0d;
}

@font-face {
  font-family: 'MonoLisa';
  src: url('../fonts/MonoLisa-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

@font-face {
  font-family: 'MonoLisa';
  src: url('../fonts/MonoLisa-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Base
body {
  position: relative;
  font-family: 'MonoLisa', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: var(--font-size);
  line-height: 1.5;
  color: var(--color);
  background-color: var(--bg-color);
}

p {
  margin: 0 0 0.5em;
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0.5em;
    width: 100%;
    height: 50%;
    background-color: var(--color);
    opacity: 0.15;
    pointer-events: none;
    z-index: -1;
  }

  &:hover {
    &::before {
      animation: flicker 0.25s linear;
      animation-fill-mode: none;
    }
  }
}

// Focus
:focus {
  outline: 0.1em solid var(--color);
}

:focus:not(:focus-visible) {
  outline: 0;
}

:focus-visible {
  outline: 0.1em solid var(--color);
}

// Selection
::selection {
  color: var(--bg-color);
  background: var(--color);
}

// Components
.wrapper {
  overflow: hidden;
}

// Flicker Animation
@keyframes flicker {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.25;
    transform: translate(-0.1em, -0.1em);
  }
  20% {
    opacity: 0;
    transform: translate(0, 0);
  }
  30% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.35;
    transform: translate(0.1em, -0.1em);
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
    transform: none;
  }
  100% {
    opacity: 0.5;
  }
}

@import 'theme-toggle';
