html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --color: #0d0d0d;
  --bg-color: #fcfcfc;
  --font-size: .75rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color: #fcfcfc;
    --bg-color: #0d0d0d;
  }
}

@media (min-width: 375px) {
  :root {
    --font-size: 1rem;
  }
}

@media (min-width: 600px) and (min-height: 600px) {
  :root {
    --font-size: 1.25rem;
  }
}

@media (min-width: 800px) and (min-height: 800px) {
  :root {
    --font-size: 1.5rem;
  }
}

@media (min-width: 1000px) and (min-height: 1000px) {
  :root {
    --font-size: 1.75rem;
  }
}

@media (min-width: 1200px) and (min-height: 1200px) {
  :root {
    --font-size: 2rem;
  }
}

.color-scheme-light {
  --color: #0d0d0d;
  --bg-color: #fcfcfc;
}

.color-scheme-dark {
  --color: #fcfcfc;
  --bg-color: #0d0d0d;
}

@font-face {
  font-family: MonoLisa;
  src: url("MonoLisa-Regular.8737d9c6.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

@font-face {
  font-family: MonoLisa;
  src: url("MonoLisa-Bold.3c19daf4.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: MonoLisa, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: var(--font-size);
  color: var(--color);
  background-color: var(--bg-color);
  line-height: 1.5;
  position: relative;
}

p {
  margin: 0 0 .5em;
}

a {
  color: inherit;
  text-decoration: none;
  position: relative;
}

a:before {
  content: "";
  width: 100%;
  height: 50%;
  background-color: var(--color);
  opacity: .15;
  pointer-events: none;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: .5em;
}

a:hover:before {
  animation: flicker .25s linear;
}

:focus {
  outline: .1em solid var(--color);
}

:focus:not(:focus-visible) {
  outline: 0;
}

:focus-visible {
  outline: .1em solid var(--color);
}

::selection {
  color: var(--bg-color);
  background: var(--color);
}

.wrapper {
  overflow: hidden;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }

  10% {
    opacity: .25;
    transform: translate(-.1em, -.1em);
  }

  20% {
    opacity: 0;
    transform: translate(0);
  }

  30% {
    opacity: .5;
  }

  40% {
    opacity: .15;
  }

  50% {
    opacity: .35;
    transform: translate(.1em, -.1em);
  }

  60% {
    opacity: 0;
  }

  70% {
    opacity: .5;
  }

  80% {
    opacity: 0;
    transform: none;
  }

  100% {
    opacity: .5;
  }
}

.theme-toggle {
  width: 1.75rem;
  height: 1.75rem;
  color: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.theme-toggle svg {
  width: 1em;
  height: 1em;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.theme-toggle .dark {
  opacity: 1;
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .theme-toggle .light {
    opacity: 1;
    visibility: visible;
  }

  .theme-toggle .dark {
    opacity: 0;
    visibility: hidden;
  }
}

.color-scheme-light .theme-toggle .light {
  opacity: 0;
  visibility: hidden;
}

.color-scheme-light .theme-toggle .dark, .color-scheme-dark .theme-toggle .light {
  opacity: 1;
  visibility: visible;
}

.color-scheme-dark .theme-toggle .dark {
  opacity: 0;
  visibility: hidden;
}

/*# sourceMappingURL=index.764dee5c.css.map */
